import {
  getVideosBySections,
  getVoiceOvers,
  validateFulfilled,
  buildVideoSection,
  getVoiceOverMessages,
  getErrorMessages,
  updateVideoWithError,
  ensureSpacesAroundSeparator
} from '../helpers/helper';
import { voices } from '../data/voices/voices';
import { clone } from '~/helpers/javascript';
import { getCredits, updateCredits } from '../helpers/credits.services';
import Toast from '~/helpers/notification';

export const useEditStore = (set, get) => ({
  // state
  playerEditStore: null,
  showEdit: false,
  videoEditPreview: {},
  imageEditPreview: null,
  videoEditProportion: 0.3,
  videoEdit: {},
  hasEditedMetadata: false,
  hasEditedScenes: false,
  hasEditedMedias: false,
  isEditingError: false,
  sceneIdx: 0,

  // actions
  setPlayerEditStore: (playerEditStore) => {
    set({ playerEditStore });
  },

  setShowEditAction: (idx = 0) => {
    if (!get().user) {
      get().redirectSignUpAction();
    } else {
      let newProportion = Math.min(window.innerWidth / 1080, window.innerHeight / 1920);

      if (window.innerWidth < 962) {
        newProportion = newProportion - 0.0355;
      }
      if (window.innerWidth < 475) {
        newProportion = 0.3;
      }
      if (window.innerWidth < 390) {
        newProportion = 0.25;
      }

      set({ videoEditProportion: newProportion });

      const sections = get().activePage === 'create' ? get().sections : get().historicalSections;
      const videosBySections =
        get().activePage === 'create' ? get().videosBySections : get().videosByHistoricalSections;

      let _video = clone(videosBySections[idx]?.video);
      _video.proportion = get().videoEditProportion;

      const layer = { ..._video.clips[0]?.layers[0] };
      let media = layer.path;
      if (media.type !== 'woxo-image') {
        media = media.replaceAll('.mp4', '.jpg');
      }

      let _videoEdit = {
        id: _video.id,
        title: _video.meta.title,
        description: _video.meta.description,
        hashtags: _video.meta.hashtags,
        scenes: [],
        sectionIdx: 0,
        videoIdx: 0,
        idx: idx
      };

      const _videos = [...videosBySections];
      _videos.map((v) => {
        if (v.video.id === _videoEdit.id) {
          _videoEdit.sectionIdx = v.sectionIdx;
          _videoEdit.videoIdx = v.videoIdx;

          const _section = sections[v.sectionIdx];
          _section?.voiceover[v.videoIdx]?.scenes.forEach((s, sIdx) => {
            // TODO: value remove that only have 2 socks (now the plugin is not done)
            const _media =
              _section?.medias[v.videoIdx].length === 2
                ? sIdx % 2 === 0
                  ? _section?.medias[v.videoIdx][0]
                  : _section?.medias[v.videoIdx][1]
                : _section?.medias[v.videoIdx][sIdx];

            if (get().preset.value !== 'typewriter') {
              _videoEdit.scenes.push({ media: _media, voiceover: s.captions });
            } else _videoEdit.scenes.push({ media: _media, voiceover: s.voiceover });
          });
        }
      });

      set({
        videoEditPreview: _video,
        imageEditPreview: media,
        videoEdit: _videoEdit,
        showEdit: true,
        isEditingError: false,
        hasEditedMetadata: false,
        hasEditedScenes: false,
        hasEditedMedias: false
      });

      setTimeout(() => {
        let body = document.querySelector('body');
        if (body) {
          body.style.overflowY = 'hidden';
        }
      });

      get().trackingAction({
        event: 'onButtonClick',
        category: 'idea-to-video',
        action: 'edit-video'
      });
    }
  },

  hideEditAction: () => {
    let body = document.querySelector('body');
    if (body) {
      body.style.overflowY = 'scroll';
    }

    const playerEditStore = get().playerEditStore;

    playerEditStore?.current?.setState({ creationId: '' });
    playerEditStore?.current?.getState().destroyMovie();
    playerEditStore?.current?.destroy();

    set({ showEdit: false, playerEditStore: null });
  },

  applyChangesAction: async (video) => {
    const sections = get().activePage === 'create' ? get().sections : get().historicalSections;
    const videosBySections =
      get().activePage === 'create' ? get().videosBySections : get().videosByHistoricalSections;

    let _sections = clone(sections);
    _sections[video.sectionIdx].isLoading = true;

    set({
      isLoading: true,
      changeRunning: 'voiceover',
      voiceoverMessage: getVoiceOverMessages(),
      errorMessage: getErrorMessages(),
      isEditingError: false
    });

    let _video = clone(videosBySections[video.idx]?.video);
    _video.meta = {
      ..._video.meta,
      title: video.title,
      description: video.description,
      hashtags: video.hashtags
    };

    let _voiceover = { scenes: [] };
    let _medias = _sections[video.sectionIdx].medias;
    _medias[video.videoIdx] = [];
    video.scenes.forEach((s) => {
      if (get().preset.value !== 'typewriter') {
        _voiceover.scenes.push({
          voiceover: s.voiceover.replace(/[~*]/g, ''),
          captions: ensureSpacesAroundSeparator(s.voiceover)
        });
      } else {
        _voiceover.scenes.push({
          voiceover: s.voiceover
        });
      }
      _medias[video.videoIdx].push(s.media);
    });

    if (get().hasEditedScenes) {
      if (get().credits > 0) {
        // !- INFO: validate credits
        try {
          const creditsRemaining = await getCredits(get().user._id, get().plan);
          if (get().editionCosts <= creditsRemaining) {
            // VOICE-OVER
            let _sectionVoiceover = _sections[video.sectionIdx].voiceover;
            _sectionVoiceover[video.videoIdx] = _voiceover;

            const _voiceOvers = await getVoiceOvers(
              _sectionVoiceover,
              get().voiceOver,
              voices,
              get().preset.value
            );
            const fulfilled = validateFulfilled(_voiceOvers);
            if (fulfilled) {
              let voiceOvers = [];
              _voiceOvers.forEach((m) => {
                voiceOvers.push(m.value);
              });

              let section = _sections[video.sectionIdx];
              section.videos[video.videoIdx] = _video;

              const _currentSection = section;
              const _voiceoverAudio = { disabled: get().voiceOver.disabled, audios: voiceOvers };
              const _music = section.music;
              let _voiceover = _sectionVoiceover;

              const _section = buildVideoSection(
                _currentSection,
                _voiceover,
                _medias,
                _voiceoverAudio,
                _music,
                get().videoProportion,
                get().preset.value,
                get().textStyles
              );

              // !- INFO: discount the credits
              const data = {
                userId: get().user._id,
                usage: get().editionCosts,
                plan: get().plan,
                action: 'idea-to-video-video-editing'
              };
              const creditsRemaining = await updateCredits(data);

              if (creditsRemaining >= 0) {
                _sections[video.sectionIdx] = _section;
                set(
                  {
                    isLoading: false,
                    changeRunning: 'none',
                    videoEditPreview: { ..._video, proportion: get().videoEditProportion },
                    credits: creditsRemaining
                  },
                  false,
                  'Update states'
                );

                // toast for credits
                Toast.success(
                  `Discount applied! ${get().editionCosts} credits deducted for video editing.`
                );

                if (get().activePage === 'create') {
                  set(
                    {
                      sections: [..._sections],
                      videosBySections: getVideosBySections(_sections)
                    },
                    false,
                    'Update section'
                  );
                } else {
                  set(
                    {
                      historicalSections: [..._sections],
                      videosByHistoricalSections: getVideosBySections(_sections)
                    },
                    false,
                    'Update section'
                  );
                }

                // !- Update video in history
                if (_section.videos[0]?.isCreated) {
                  get().updateOneVideoHistoryAction(get().user._id, _section, video.videoIdx);
                }
              } else {
                // ERROR _ CREDITS
                set(
                  {
                    isLoading: false,
                    changeRunning: 'none',
                    isEditingError: true,
                    videoEditPreview: updateVideoWithError(get().videoEditPreview)
                  },
                  false,
                  'Update section _ error  _ credits'
                );
              }
            } else {
              // ERROR
              set(
                {
                  isLoading: false,
                  changeRunning: 'none',
                  isEditingError: true,
                  videoEditPreview: updateVideoWithError(get().videoEditPreview)
                },
                false,
                'Update section _ error'
              );
            }
          } else {
            get().setShowCreditsAction(true);

            _sections[video.sectionIdx].isLoading = false;

            set(
              {
                sections: _sections,
                isLoading: false,
                changeRunning: 'none'
              },
              false,
              'Update section _ error _ credits'
            );
          }
        } catch (error) {
          console.error('ERROR - CREDITS', error);

          set(
            {
              isLoading: false,
              changeRunning: 'none',
              isEditingError: true,
              videoEditPreview: updateVideoWithError(get().videoEditPreview)
            },
            false,
            'Update section _ error _ credits'
          );

          throw new Error('Error in getting the credits');
        }
      } else {
        get().setShowCreditsAction(true);
      }
    } else {
      let section = _sections[video.sectionIdx];
      section.videos[video.videoIdx] = _video;

      const _currentSection = section;
      const _voiceoverAudio = section.voiceoverAudio;
      let _voiceover = section.voiceover;
      const _music = section.music;

      const _section = buildVideoSection(
        _currentSection,
        _voiceover,
        _medias,
        _voiceoverAudio,
        _music,
        get().videoProportion,
        get().preset.value,
        get().textStyles
      );

      _sections[video.sectionIdx] = _section;
      set(
        {
          isLoading: false,
          changeRunning: 'none',
          videoEditPreview: { ..._video, proportion: get().videoEditProportion }
        },
        false,
        'Update states'
      );

      if (get().activePage === 'create') {
        set(
          {
            sections: [..._sections],
            videosBySections: getVideosBySections(_sections)
          },
          false,
          'Update section'
        );
      } else {
        set(
          {
            historicalSections: [..._sections],
            videosByHistoricalSections: getVideosBySections(_sections)
          },
          false,
          'Update section'
        );
      }

      // !- Update video in history
      if (_section.videos[0]?.isCreated) {
        get().updateOneVideoHistoryAction(get().user._id, _section, video.videoIdx);
      }
    }

    set({
      hasEditedMetadata: false,
      hasEditedScenes: false,
      hasEditedMedias: false
    });

    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'apply-changes-edit-video'
    });
  },

  setScenesAction: (e, i) => {
    const _video = { ...get().videoEdit };
    _video.scenes[i] = {
      ..._video.scenes[i],
      voiceover: e.target.value
    };

    set({ videoEdit: _video, hasEditedScenes: true });
  },

  setVideoDataAction: (e) => {
    const _video = { ...get().videoEdit, [e.target.name]: e.target.value };

    set({ videoEdit: _video, hasEditedMetadata: true });
  }
});
